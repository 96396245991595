import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import "firebase/storage";
import store from "@/store";

const firebaseConfig = {
  apiKey: "AIzaSyCp-nklEPoLNcXJBnq9hIYAUrk5LGuLh2s",
  authDomain: "lowell-place-apartments.firebaseapp.com",
  databaseURL: "https://lowell-place-apartments.firebaseio.com",
  projectId: "lowell-place-apartments",
  storageBucket: "lowell-place-apartments.appspot.com",
  messagingSenderId: "693630096527",
  appId: "1:693630096527:web:a434a52ec397e8823a8cfa",
  measurementId: "G-JB45QTNXFK"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
const functions = firebase.functions();
var storage = firebase.storage();

auth.onAuthStateChanged(user => {
  if (user) {
    user.getIdTokenResult().then(idTokenResult => {
      user.admin = idTokenResult.claims.admin;
    });

    store.dispatch("updateUser", user);
  } else {
    store.dispatch("updateUser", null);
  }

  store.dispatch("changeAuthReady");
});

export { db, auth, functions, storage };
